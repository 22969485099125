<template>
  <div class="main_container">
    <div class="task_detail_container">

      <!-- 详情页头部 -->
      <detail-title
        :backToPath="'/company/manage'"
        :descriptionList="descriptionList"
      ></detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :descriptionList="descriptionList"
        :isMaterials="true">
      </task-description>

      <!-- 接受任务按钮 -->
      <div
        class="task_submit"
        v-if="descriptionList.status === 5">
        <el-button
          size="small"
          type="primary"
          @click="republishTask">
          {{
            {
              5: '重新发布'
            }[descriptionList.status]
          }}
        </el-button>
      </div>
    </div>

    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>
  </div>
</template>

<script>
import api from '@/api/index'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    TaskDescription
  },
  data () {
    return {
      descriptionList: {}
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getDetailInfo()
  },
  methods: {
    // 获取任务详情
    getDetailInfo () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data ? res.data.data : {}
        } else {
          this.$message.error('任务详情获取失败')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    },
    republishTask () {
      this.$router.push(`/company/task-release/${this.taskId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #E4E7ED;
    display: flex;
    align-items: center;
  }
}
</style>
